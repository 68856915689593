import { api } from '@api';
import { LogoLoader } from '@components/UIKit/LogoLoader/LogoLoader';
import { getRandomIntInclusive } from '@helpers/helpers';
import { useHeaderHeight } from '@hooks/useHeaderHeight/useHeaderHeight';
import React, { FC, useEffect, useState } from 'react';
import { Banner } from '../Banner/Banner';
import s from './feedBanner.module.scss';
interface FeedBannerProps {}
const FeedBannerMemo: FC<FeedBannerProps> = ({}) => {
  const headerHeight = useHeaderHeight();
  const [index, setIndex] = useState<undefined | number>(undefined);
  const {
    data,
    isLoading
  } = api.ContentManager.getPromo.useQuery(undefined, {
    queryKey: ['ContentManager.getPromo', undefined],
    refetchOnWindowFocus: false,
    staleTime: 60 * 60 * 1000
  });
  useEffect(() => {
    if (!data?.length) return;
    setIndex(getRandomIntInclusive(0, data.length - 1));
  }, [data]);
  if (isLoading) return <LogoLoader />;
  if (!data?.length || typeof index === 'undefined') return null;
  return <div className={s.feedBanner} style={{
    top: `${headerHeight}px`
  }} data-sentry-component="FeedBannerMemo" data-sentry-source-file="FeedBanner.tsx">
            <Banner isVertical={true} {...data[index]} data-sentry-element="Banner" data-sentry-source-file="FeedBanner.tsx" />
        </div>;
};
export const FeedBanner = React.memo(FeedBannerMemo);