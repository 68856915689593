import { useEffect, useState } from 'react'

export const useReportedPosts = () => {
    const [reportedPosts, setReportedPosts] = useState<number[]>([])

    useEffect(() => {
        const handleStorageChange = () => {
            const storedPosts = JSON.parse(localStorage.getItem('postReports') || '[]')
            setReportedPosts(storedPosts)
        }

        handleStorageChange() // Initial load
        window.addEventListener('storage', handleStorageChange)

        return () => {
            window.removeEventListener('storage', handleStorageChange)
        }
    }, [])

    return reportedPosts
}
