import { toast } from 'react-toastify';
import { Toast, ToastProps } from '@components/UIKit/Toast/Toast';
import { type Dictionary, useTranslation } from '@hooks/useTranslation';
import React from 'react';
import enDictionary from '@lang/en';

// Type guard для проверки наличия ключа в enDictionary
const isKeyInDictionary = (key: string, dictionary: typeof enDictionary): key is Dictionary => key in dictionary;
export const useShowToast = () => {
  const {
    translate: tr
  } = useTranslation();
  const showToast = ({
    variant,
    text,
    icon,
    title
  }: Omit<ToastProps, 'title'> & {
    title?: ToastProps['title'];
  }) => {
    const currentTitle = typeof title !== 'undefined' ? title : variant === 'success' ? 'successful' : variant === 'error' ? 'unsuccessful' : 'info';
    const messageText = typeof text === 'undefined' ? undefined : isKeyInDictionary(text, enDictionary) ? tr({
      id: text
    }) : text;
    const messageTitle = isKeyInDictionary(currentTitle, enDictionary) ? tr({
      id: currentTitle
    }) : currentTitle;
    toast(props => <Toast text={messageText} title={messageTitle} icon={icon} variant={variant} {...props} />);
  };
  return {
    showToast
  };
};