import { ExpectedProps, ServerPropsFactory } from '@lib/factory/serverprops';
import { Skeleton } from '@mui/material';
import cn from 'classnames';
import { NextPage } from 'next';
import { useSession } from 'next-auth/react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Suspense } from 'react';
import { useTranslation } from '@hooks/useTranslation';
import { FeedBanner } from '@components/Cards/FeedBanner/FeedBanner';
import { PageLayout } from '@components/PageLayout/PageLayout';
import { PersonalPostFeedList } from '@components/Pages/FeedPage/PostsLists/PersonalPostFeedList';
import { PublicList } from '@components/Pages/FeedPage/PostsLists/PublicList';
import { LogoLoader } from '@components/UIKit/LogoLoader/LogoLoader';
import { Search } from '@components/UIKit/Search/Search';
import { cookies } from '@specs/features/cookies';
import { LinksEnum } from '@specs/navigation';
import s from './feedPages.module.scss';
export const getServerSideProps = ServerPropsFactory(async (props: ExpectedProps) => {
  const redirectData = cookies.getFromServerSideProps(props, cookies.REDIRECT_AFTER_AUTH_COOKIE_KEY);
  return {
    ...props,
    redirectData: !redirectData
  };
}, [{
  key: cookies.REDIRECT_AFTER_AUTH_COOKIE_KEY
}], true);
const NewsList = dynamic(() => import('@components/Pages/FeedPage/NewsList/NewsList').then(mod => mod.NewsList), {
  loading: () => <Skeleton variant="rectangular" height={200} />,
  ssr: false
});
interface PageProps extends ExpectedProps {}
const Page: NextPage<PageProps> = ({
  agent
}) => {
  const router = useRouter();
  const {
    translate: tr
  } = useTranslation();
  const {
    status
  } = useSession();
  return <PageLayout data-sentry-element="PageLayout" data-sentry-component="Page" data-sentry-source-file="index.tsx">
            <NewsList data-sentry-element="NewsList" data-sentry-source-file="index.tsx" />
            <div className={s.feedPages}>
                <div className={cn(s.feedPages__container, '_container')}>
                    {/* <div className={s.feedPages__top}>
                        <div className={s.feedPages__tabs}>
                            <TabItems />
                        </div>
                        {router.pathname !== LinksEnum.FEED_PHYSICAL && (
                            <Search
                                boxClassName={s.feedPages__search}
                                placeholder={tr({ id: 'feed.page.posts.placeholder' })}
                                name={'search'}
                            />
                        )}
                     </div> */}
                    {router.pathname === LinksEnum.FEED_POSTS && <div className={s.feedPages__postsWrapper}> 
                            <div>
                                <div className={s.feedPages__top}>
                                    <Search boxClassName={s.feedPages__search} placeholder={tr({
                id: 'feed.page.posts.placeholder'
              })} name={'search'} />
                                </div> 
                                {status === 'authenticated' ? <PersonalPostFeedList agent={agent} /> : status === 'unauthenticated' ? <PublicList agent={agent} /> : <LogoLoader />}
                            </div>
                            <Suspense fallback={<div>Loading...</div>}>
                                <FeedBanner />
                            </Suspense>
                        </div>}
                    {/* {router.pathname === LinksEnum.FEED_TOYS && (
                        <>
                            <div className={s.feedPages__toysSorting}>
                                <FilterToysRaritySelect />
                                <Sorting />
                            </div>
                            {status === 'authenticated' ? <ToysPrivateList /> :
                                status === 'unauthenticated' ? <ToysPublicList /> :
                                    <LogoLoader />}
                        </>
                     )}
                     {router.pathname === LinksEnum.FEED_PHYSICAL && (
                        <PhysicalToysList />
                     )} */}
                </div>
            </div>
        </PageLayout>;
};
export default Page;