import React, { type FC } from 'react';
import s from './toast.module.scss';
import { type ToastContentProps } from 'react-toastify';
import cn from 'classnames';
import { SpriteIcon, SpriteIconProps } from '@components/Sprites/SpriteIcon';
export type toastVariantTypes = 'success' | 'error' | 'info';
export interface ToastProps {
  variant: toastVariantTypes;
  title: string;
  text?: string;
  icon?: Omit<SpriteIconProps, 'width' | 'height' | 'color'>;
}
type Props = ToastProps & ToastContentProps<unknown>;
export const Toast: FC<Props> = ({
  variant = 'success',
  title,
  text,
  icon
}) => {
  return <div className={cn(s.toast, variant === 'success' && s.toast_success, variant === 'error' && s.toast_error)} data-sentry-component="Toast" data-sentry-source-file="Toast.tsx">
            <div className={s.toast__icon}>
                <SpriteIcon name={variant === 'error' ? 'close' : 'check'} width={20} height={20} color={'--white'} {...icon} data-sentry-element="SpriteIcon" data-sentry-source-file="Toast.tsx" />
            </div>
            <div className={s.toast__body}>
                <div className={s.toast__title}>
                    {title}
                </div>
                {text && <div className={s.toast__text}>{text}</div>}
            </div>
        </div>;
};