import { api, ReactQueryOptions, RouterInputs, RouterOutputs } from '@api';
import { staleTimeIndex } from '@specs/constants';
import { TRPCClientErrorLike } from '@trpc/client';
import { UseTRPCInfiniteQueryOptions } from '@trpc/react-query/shared';
type inputType = RouterInputs['PostsManager']['getPersonalFeed'];
type outputType = RouterOutputs['PostsManager']['getPersonalFeed'];
type optionsType = UseTRPCInfiniteQueryOptions<'PostsManager.getPersonalFeed', inputType, outputType, TRPCClientErrorLike<any>>;
export const useGetInfinityPostPersonalFeed = (input: inputType, options?: optionsType) => api.PostsManager.getPersonalFeed.useInfiniteQuery(input, {
  ...options,
  queryKey: ['PostsManager.getPersonalFeed', input],
  getNextPageParam: firstPage => firstPage.nextCursor,
  initialCursor: undefined,
  staleTime: 1000 * staleTimeIndex * 60 // 1 minute
});
type queryOptionsType = ReactQueryOptions['PostsManager']['getPersonalFeed'];
export const useGetPostsPersonalFeed = (input: inputType, options?: queryOptionsType) => api.PostsManager.getPersonalFeed.useQuery(input, {
  queryKey: ['PostsManager.getPersonalFeed', input],
  staleTime: 1000 * staleTimeIndex * 60,
  // 1 minute
  ...options
});