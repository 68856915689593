import { api } from '@api';
import { PaginatedGrid } from '@components/Grids/PaginatedGrid';
import { useReportedPosts } from '@hooks/useReportedPosts';
import { useGetPostsPublicFeed } from '@services/posts/hooks/useGetPostsFeed';
import { PageQueries } from '@specs/navigation';
import { useUserAgent } from 'next-useragent';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { Skeleton } from '@mui/material';
type PublicListProps = {
  agent?: string;
};
const limit = 10;
const PostCardPreview = dynamic(() => import('@components/Cards/PostCard/PostCardPreview').then(mod => mod.PostCardPreview), {
  loading: () => <Skeleton variant="rectangular" height={200} />,
  ssr: false
});
export const PublicList: FC<PublicListProps> = ({
  agent
}) => {
  const router = useRouter();
  const utils = api.useUtils();
  const {
    isMobile
  } = useUserAgent(agent ?? '');
  const gridRef = useRef<HTMLDivElement>(null);
  const {
    query,
    page = '1'
  }: PageQueries = router.query;
  const currentPage = Number(page);
  const prevPageRef = useRef<number>(currentPage);
  const pageChangeRef = useRef(false);
  const reportedPosts = useReportedPosts();
  const queryParams = useMemo(() => ({
    limit,
    skip: (currentPage - 1) * limit,
    query
  }), [currentPage, query]);
  const {
    data: rawData,
    status
  } = useGetPostsPublicFeed(queryParams);

  // Filter out reported posts
  const data = useMemo(() => {
    if (!rawData?.items) return rawData;
    return {
      ...rawData,
      items: rawData.items.filter(post => !reportedPosts.includes(post.id))
    };
  }, [rawData, reportedPosts]);
  const handlePageChange = useCallback((newPage: number) => {
    pageChangeRef.current = true;
    router.push({
      query: {
        ...router.query,
        page: newPage.toString()
      }
    }, undefined, {
      scroll: false
    });
  }, [router]);
  useEffect(() => {
    if (currentPage !== prevPageRef.current) {
      pageChangeRef.current = true;
      prevPageRef.current = currentPage;
    }
    if (pageChangeRef.current && status === 'success' && data?.items.length) {
      if (gridRef.current) {
        window.scrollTo({
          top: gridRef.current.offsetTop - 100,
          behavior: 'smooth'
        });
      }
      pageChangeRef.current = false;
    }
  }, [currentPage, status, data]);
  useEffect(() => {
    if (!data?.items) return;
    data.items.forEach(post => {
      utils.PostsManager.getPost.setData({
        post_id: post.id
      }, post);
    });
  }, [data, utils.PostsManager.getPost]);
  const renderPost = useCallback(post => <PostCardPreview isMobile={isMobile} key={post.id} initialPost={post} />, [isMobile]);
  return <div ref={gridRef} data-sentry-component="PublicList" data-sentry-source-file="PublicList.tsx">
            <PaginatedGrid data={data} currentPage={currentPage} onPageChange={handlePageChange} status={status} gridVariant='feed' skipFirstBanner showBanners={isMobile} data-sentry-element="PaginatedGrid" data-sentry-source-file="PublicList.tsx">
                {renderPost}
            </PaginatedGrid>
        </div>;
};