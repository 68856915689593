// import React, { FC, useRef } from 'react'
// import { PageQueries } from '@specs/navigation'
// import { useRouter } from 'next/router'
// import { useUserAgent } from 'next-useragent'
// import { Grid } from '@components/Grids/Grid'
// import { PostCardPreview } from '@components/Cards/PostCard/PostCardPreview'
// import { useGetInfinityPostPersonalFeed } from '@services/posts/hooks/useGetPersonalPostFeed'

// type ListProps = {
//     agent?: string
// }

// const limit = 5

// /**
//  * Компонент для отображения списка постов пользователей с его подписками и новостями
//  */

// export const PersonalPostFeedList: FC<ListProps> = ({ agent }) => {
//     const { isMobile } = useUserAgent(agent ?? '')
//     const enabledRef = useRef(false)
//     const router = useRouter()

//     const { query }: PageQueries = router.query

//     const {
//         data,
//         fetchNextPage,
//         hasNextPage,
//         isFetchingNextPage,
//         status,
//     } = useGetInfinityPostPersonalFeed({ limit, query }, {
//         enabled: enabledRef.current,
//     })

//     const fetchNext = async () => { await fetchNextPage() }

//     return (
//         <div>
//             <Grid
//                 data={data}
//                 enabledRef={enabledRef}
//                 isFetchingNextPage={isFetchingNextPage}
//                 hasNextPage={hasNextPage}
//                 fetchNext={fetchNext}
//                 status={status}
//                 limit={limit}
//                 gridVariant='feed'
//                 skipFirstBanner
//                 showBanners={isMobile}
//             >
//                 {post => <PostCardPreview isMobile={isMobile} key={post.id} initialPost={post} />}
//             </Grid>
//         </div>
//     )
// }

import { api } from '@api';
import { PostCardPreview } from '@components/Cards/PostCard/PostCardPreview';
import { PaginatedGrid } from '@components/Grids/PaginatedGrid';
import { useReportedPosts } from '@hooks/useReportedPosts';
import { useGetPostsPersonalFeed } from '@services/posts/hooks/useGetPersonalPostFeed';
import { PageQueries } from '@specs/navigation';
import { useUserAgent } from 'next-useragent';
import { useRouter } from 'next/router';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
type ListProps = {
  agent?: string;
};
const limit = 10;
export const PersonalPostFeedList: FC<ListProps> = ({
  agent
}) => {
  const router = useRouter();
  const {
    isMobile
  } = useUserAgent(agent ?? '');
  const gridRef = useRef<HTMLDivElement>(null);
  const {
    query,
    page = '1'
  }: PageQueries = router.query;
  const currentPage = Number(page);
  const prevPageRef = useRef<number>(currentPage);
  const pageChangeRef = useRef(false);
  const utils = api.useUtils();
  const reportedPosts = useReportedPosts();
  const queryParams = useMemo(() => ({
    limit,
    skip: (currentPage - 1) * limit,
    query
  }), [currentPage, query]);
  const {
    data: rawData,
    status
  } = useGetPostsPersonalFeed(queryParams, {
    cacheTime: 0,
    staleTime: 0
  });
  const data = useMemo(() => {
    if (!rawData?.items) return rawData;
    return {
      ...rawData,
      items: rawData.items.filter(post => !reportedPosts.includes(post.id))
    };
  }, [rawData, reportedPosts]);
  const handlePageChange = useCallback((newPage: number) => {
    pageChangeRef.current = true;
    router.push({
      query: {
        ...router.query,
        page: newPage.toString()
      }
    }, undefined, {
      scroll: false
    });
  }, [router]);
  useEffect(() => {
    if (currentPage !== prevPageRef.current) {
      pageChangeRef.current = true;
      prevPageRef.current = currentPage;
    }
    if (pageChangeRef.current && status === 'success' && data?.items.length) {
      if (gridRef.current) {
        window.scrollTo({
          top: gridRef.current.offsetTop - 100,
          behavior: 'smooth'
        });
      }
      pageChangeRef.current = false;
    }
  }, [currentPage, status, data]);
  useEffect(() => {
    if (!data?.items) return;
    data.items.forEach(post => {
      utils.PostsManager.getPost.setData({
        post_id: post.id
      }, post);
    });
  }, [data, utils.PostsManager.getPost]);
  const renderPost = useCallback(post => <PostCardPreview isMobile={isMobile} key={post.id} initialPost={post} />, [isMobile]);
  return <div ref={gridRef} data-sentry-component="PersonalPostFeedList" data-sentry-source-file="PersonalPostFeedList.tsx">
            <PaginatedGrid data={data} currentPage={currentPage} onPageChange={handlePageChange} status={status} gridVariant='feed' skipFirstBanner showBanners={isMobile} data-sentry-element="PaginatedGrid" data-sentry-source-file="PersonalPostFeedList.tsx">
                {renderPost}
            </PaginatedGrid>
        </div>;
};